.Stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 35px;
    color: #FFFFFF;
    text-align: center;
}

.LeftTeamPercentage {
    color: #000000;
    background-color: var(--mf-yellow-color);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    align-self: center;
    min-width: 345px;
}

.LeftTeamPercentage.WFT {
    margin-left: 20px;
    color: #FFFFFF;
    background-color: var(--wft-green-color);
    border-color: #FFFFFF;
    border-top-left-radius: 0px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 0px;
    border-style: solid;
    border-width: 5px;
}

.RightTeamPercentage {
    color: #FFFFFF;
    background-color: var(--mf-black-color);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    align-self: center;
    min-width: 345px;
}

.RightTeamPercentage.WFT {
    margin-right: 20px;
    color: #FFFFFF;
    background-color: var(--wft-green-color);
    border-color: #FFFFFF;
    border-top-left-radius: 25px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 25px;
    border-style: solid;
    border-width: 5px;
}

.PercentagesRow {
    margin: 30px;
}

.PercentagesTitle {
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 600;
}

.PercentagesValue {
    margin-left: 5px;
    margin-right: 5px;
}

.PercentagesPercentValue {
    margin-left: 30px;
    font-weight: 900;
}

.SummaryColumn {
    background: rgb(154, 24, 32, 0.95);
    border-radius: 25px;
    align-self: center;
    min-width: 450px;
}

.SummaryColumn.WFT {
    background: var(--wft-blue-color);
    border-color: #FFFFFF;
    border-style: solid;
    border-width: 5px;
}

.SummaryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
}

.SummaryRowHeader {
    margin-bottom: 10px;
}

.SummarySeparator {
    display: block;
    height: 5px;
    background-color: #FFFFFF;
}

.SummaryRowUnderHeader {
    margin-top: 15px;
}

.SummaryTitle {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 35px;
}

.SummaryRowHeader .SummaryTitle {
    font-size: 42px;
}

.SummaryValue {
    min-width: 60px;
    text-align: center;
    font-weight: 900;
    color: #FFFFFF;
    font-size: 42px;
}