.Score {
    width: 308px;
    float: left;
    background-color: var(--mf-red-color);
    display: flex;
}

.Timeouts {
    width: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Points {
    width: 74%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.GameScore {
    height: 75%;
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.LeftTeamScore {
    height: 100%;
    width: 38%;
    display: block;
    text-align: right;
}

.ScoreSeparator {
    height: 100%;
    width: 24%;
    text-align: center;
}

.RightTeamScore {
    height: 100%;
    width: 38%;
    text-align: left;
}

.MatchScore {
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.GamesToWinMatchTitle {
    text-transform: uppercase;
    color: #FFFFFF;
    text-transform: uppercase;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.627px;
}

.GameCounter {
    width: 215px;
    height: 8px;
    margin-top: 3px;
    margin-bottom: 11px;

    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
}

.Game {
    display: block;
    height: 8px;
    border-color: white;
    border-radius: 2px;
    margin-left: 2px;
    margin-right: 2px;
}

.GameUnplayed {
    background-color: var(--mf-greyed-out-red-color);
}

.Game.BlackTeamColors {
    background-color: black;
}

.NoScore {
    width: 308px;
    background-color: var(--mf-red-color);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Waiting {
    width: 308px;
    background-color: var(--mf-red-color);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.MatchPending {
    width: 308px;
    background-color: var(--mf-red-color);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
}