.Scoreboard {
    position: absolute;
    width: 1920px;
    height: 117px;
    left: 0px;
    top: 963px;
    background: var(--mf-red-color);
    display: flex;
    flex-direction: row;
    z-index: 0;
}

.TriangleLeftDarker {
    position: absolute;
    z-index: -6;
    width: 200px;
    height: 117px;
    left: 0px;
    top: 0px;
    fill: var(--mf-darker-red-color);
    stroke: var(--mf-darker-red-color);
    stroke-width: 60;
}

.TriangleLeftDark {
    position: absolute;
    z-index: -5;
    width: 200px;
    height: 117px;
    left: 95px;
    top: 0px;
    fill: var(--mf-dark-red-color);
    stroke: var(--mf-dark-red-color);
    stroke-width: 60;
}

.TriangleLeftLight {
    position: absolute;
    z-index: -4;
    width: 200px;
    height: 117px;
    left: 235px;
    top: 0px;
    fill: var(--mf-red-color);
    stroke: var(--mf-red-color);
    stroke-width: 60;
}

.TriangleRightDarker {
    position: absolute;
    z-index: -3;
    width: 200px;
    height: 117px;
    left: 1550px;
    top: 0px;
    fill: var(--mf-darker-red-color);
    stroke: var(--mf-darker-red-color);
    stroke-width: 60;
}

.TriangleRightDark {
    position: absolute;
    z-index: -2;
    width: 200px;
    height: 117px;
    left: 1557px;
    top: 0px;
    fill: var(--mf-dark-red-color);
    stroke: var(--mf-dark-red-color);
    stroke-width: 60;
}

.TriangleRightLight {
    position: absolute;
    z-index: -1;
    width: 200px;
    height: 117px;
    left: 1413px;
    top: 0px;
    fill: var(--mf-red-color);
    stroke: var(--mf-red-color);
    stroke-width: 60;
}