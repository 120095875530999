.YellowTeamColors {
    color: #000000;
    background-color: var(--mf-yellow-color);
    fill: var(--mf-yellow-color);
    stroke: var(--mf-yellow-color);
    font-weight: 600;
}

.YellowTeamColors.WFT {
    color: #FFFFFF;
    background-color: var(--wft-blue-color);
    font-weight: 500;
}

.BlackTeamColors {
    color: #FFFFFF;
    background-color: var(--mf-black-color);
    fill: var(--mf-black-color);
    stroke: var(--mf-black-color);
    font-weight: 500;
}

.BlackTeamColors.WFT {
    color: #FFFFFF;
    background-color: var(--wft-blue-color);
    font-weight: 500;
}