.LogoIcon {
    position: absolute;
    width: 64px;
    height: 64px;
    left: 42px;
    top: 42px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.LogoTitle {
    position: absolute;
    left: 118px;
    top: 57px;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.09em;
    color: #FFFFFF;
}