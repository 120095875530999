.Event {
    width: 440px;
    height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}