.Format {
    width: 80px;
    height: 117px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;

    color: #FFFFFF;
    text-transform: uppercase;
}

.FormatVisible {
    background: var(--mf-black-color);
}

.FormatHidden {
    background: transparent;
}

.FormatHeader {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 96%;
    letter-spacing: 0.035em;
    color: #FFFFFF;
}

.FormatBody {
    margin-top: 5px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 96%;
    color: #FFFFFF;
}