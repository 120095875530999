@font-face {
    font-family: "Rubik";
    src: local("Rubik"),
        url("./font/static/Rubik-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Apotek";
    src: local("Apotek"),
        url("./font/apotek-black.otf") format("truetype");
}

:root {
    --font-white-color: #FFFFFF;
    --font-black-color: #000000;

    --mf-font-family: 'Rubik';
    --mf-red-color: #9A1820;
    --mf-dark-red-color: #78121A;
    --mf-darker-red-color: #4F1216;
    --mf-greyed-out-red-color: #74161B;
    --mf-yellow-color: #FFDC32;
    --mf-black-color: #161616;
    --mf-gray-color: #525252;

    --wft-font-family: 'Apotek';
    --wft-green-color: #22954C;
    --wft-blue-color: #021D43;
    --wft-orange-color: #FF4F16;
    --wft-gray-color: #525252;
}