.Odds {
    position: absolute;
    width: 1920px;
    height: 35px;
    left: 0px;
    top: 944px;
}

.OddsLine {
    position: relative;
    width: 100%;
    height: 7px;
    top: 14px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.OddsHeader {
    position: absolute;
    top: 0px;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 96%;
    letter-spacing: 0.624px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
}

.OddsHeaderLeftTeam {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 38.205px 0px 0px 38.205px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.OddsHeaderCenter {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    background-color: var(--mf-gray-color);
    color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.OddsHeaderRightTeam {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 38.205px 38.205px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}