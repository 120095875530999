.TableNumber {
    position: absolute;
    left: 1720px;
    top: 57px;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.09em;
    color: #FFFFFF;
}