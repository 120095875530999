.App {
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App.MF {
  font-family: var(--mf-font-family);
}

.App.WFT {
  font-family: var(--wft-font-family);
}