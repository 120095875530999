.ScoreboardWft {
    position: absolute;
    width: 1920px;
    height: 131px;
    left: 0px;
    top: 949px;
    background-image: url('ScoreboardWft.png');
    display: flex;
    flex-direction: row;
}

.EventWft {
    width: 317px;
    height: 131px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
}

.LogoWft {
    width: 256px;
    height: 131px;
}

.BracketWft {
    width: 317px;
    height: 131px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
}

.TeamWft {
    width: 515px;
    height: 131px;
    display: flex;
    flex-direction: row;
}

.TeamScoreWft {
    width: 70px;
    height: 131px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 42px;
    text-transform: uppercase;
}

.TeamContentWft {
    width: 445px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TeamDetailsWft {
    width: 445px;
    height: 131px;
    display: flex;
    flex-direction: row;
}

.TeamGamesWft {
    width: 445px;
}

.TeamNamesWft {
    width: 400px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    font-size: 35px;
}

.TeamNamesWft.Left {
    align-items: start;
}

.TeamNamesWft.Right {
    align-items: end;
}

.PlayerNameWft {
    width: 380px;
    margin-left: 20px;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PlayerNameWft.Left {
    text-align: left;
}

.PlayerNameWft.Right {
    text-align: right;
}

.TeamGamesWft {
    width: 334px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    position: absolute;
    top: 116px;
}

.GameWft {
    display: block;
    height: 8px;
    border-color: white;
    border-radius: 2px;
    margin-left: 2px;
    margin-right: 2px;
}

.GameWft.Won {
    background-color: #FFFFFF;
}

.GameWft.Unplayed {
    background-color: var(--wft-gray-color);
}

.TeamTimeoutsWft {
    width: 45px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}