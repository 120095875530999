.Timer {
    width: 74%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
}

.TimerSeconds {
    width: 44%;
    text-align: end;
}

.TimerSeparator {
    width: 12%;
    text-align: center;
}

.TimerTenths {
    width: 44%;
    text-align: start;
}